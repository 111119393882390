import { getWorkspaces, getWSLayers } from '@/api/services';
import {
  getDomainsWS,
  getDomainsByWS,
  getWorkspacesByPolicy,
  newWorkspace,
  updateWorkspace,
  deleteWorkspace,
  getDBLayers,
  createLayer,
  deleteLayer,
  addDomain,
  deleteDomainWS
} from '@/api/api.js';

// --------------------- STATE -----------------------------

export const state = {
  headers: [
    {
      text: 'Name',
      align: 'start',
      sortable: true,
      value: 'name'
    }
  ],
  layerHeaders: [
    {
      text: 'Workspace Name',
      align: 'start',
      sortable: true,
      value: 'ws_name'
    },
    {
      text: 'Group',
      align: 'start',
      sortable: true,
      value: 'ws_group'
    },
    {
      text: 'Layer Name',
      align: 'start',
      sortable: true,
      value: 'name'
    },
    {
      text: 'Actions',
      align: 'end',
      sortable: false,
      value: 'actions'
    }
  ],
  workspaceHeaders: [
    {
      text: 'Workspace Name',
      align: 'start',
      sortable: true,
      value: 'ws_name'
    },
    {
      text: 'Group',
      align: 'start',
      sortable: true,
      value: 'ws_group'
    }
  ],
  workspaces: [],
  workspace: {
    workspace_name: '',
    n_calls: -1,
    start_time: '',
    stop_time: '',
    date_type: '',
    policy_id: 0
  },
  callsNum: -1,
  startTime: '',
  stopTime: '',
  dateType: 'static',
  chosenLayers: [],
  DBlayers: [],
  wsDomains: [],
  domainsWS: [],
  wsDomainsToAdd: [],
  geoserverWorkspaces: [],
  cleanWorkspaces: [],
  geoserverLayers: [],
  geoserverLayersObject: [],
  isModifingWorkspace: false,
  CUDialogWS: false
};

// --------------------- MUTATIONS ----------------------------

export const mutations = {
  // GEOSERVER
  GET_WORKSPACES(state, workspacesArray) {
    state.geoserverWorkspaces = [];
    state.mappedGeoserverWorkspaces = [];
    state.cleanWorkspaces = workspacesArray;
    workspacesArray.forEach(element => {
      state.geoserverWorkspaces.push(element.name);
    });
    state.geoserverWorkspaces.sort();
  },

  GET_LAYERS(state, layersArray) {
    state.geoserverLayers = [];

    if (layersArray.length > 0) {
      layersArray.forEach(element => {
        state.geoserverLayers.push(element.name);
      });
    }
  },
  SET_LAYERS_OBJECT(state, data) {
    state.geoserverLayersObject = data;
  },
  DELETE_LAYERS(state, layersArray) {
    layersArray.forEach(element => {
      state.geoserverLayers.forEach(layer => {
        if (layer === element) {
          const index = state.geoserverLayers.indexOf(layer);
          state.geoserverLayers.splice(index, 1);
        }
      });
    });
  },

  ADD_LAYERS(state, layersArray) {
    layersArray.forEach(element => {
      state.geoserverLayers.push(element);
    });
  },

  //APPSYNC
  INSERT_WS_NAME(state, wsName) {
    state.workspace.workspace_name = wsName;
  },

  GET_WS_BY_POLICY(state, workspacesArray) {
    state.workspaces = workspacesArray;
  },

  STORE_WS(state, ws) {
    state.workspace.workspace_name = ws.workspace_name;
    state.workspace.n_calls = ws.n_calls;
    state.workspace.start_time = ws.start_time;
    state.workspace.stop_time = ws.stop_time;
    state.workspace.date_type = ws.date_type;
    state.workspace.policy_id = ws.policy_id;
    state.callsNum = ws.n_calls;
    state.startTime = ws.start_time;
    state.stopTime = ws.stop_time;
    state.dateType = ws.date_type;
  },

  NEW_WS(state, wsToAdd) {
    state.workspaces.push(wsToAdd.data.createWorkspace);
  },

  UPDATE_WS(state, wsToUpdate) {
    state.workspaces.forEach(element => {
      if (
        element.workspace_name == wsToUpdate.workspace_name &&
        element.policy_id == wsToUpdate.policy_id
      ) {
        element.n_calls = wsToUpdate.n_calls;
        element.start_time = wsToUpdate.start_time;
        element.stop_time = wsToUpdate.stop_time;
        element.date_type = wsToUpdate.date_type;
      }
    });
  },

  DELETE_WS(state, ws) {
    state.workspaces.forEach(element => {
      if (element.workspace_name == ws.workspace_name) {
        const index = state.workspaces.indexOf(element);
        state.workspaces.splice(index, 1);
      }
    });
  },

  GET_DB_LAYERS(state, layersArray) {
    layersArray.forEach(elem => {
      state.DBlayers.push(elem);
    });

    state.chosenLayers = [];
    layersArray.forEach(elem => {
      state.chosenLayers.push(elem.layer_name);
    });

    state.chosenLayers.forEach(elem => {
      state.geoserverLayers.forEach(layer => {
        if (layer === elem) {
          const index = state.geoserverLayers.indexOf(layer);
          state.geoserverLayers.splice(index, 1);
        }
      });
    });
  },

  ADD_DB_LAYER(state, newDBLayer) {
    state.DBlayers.push(newDBLayer);
  },

  DELETE_DB_LAYER(state, layerToDelete) {
    state.DBlayers.forEach(element => {
      if (
        element.workspace_name == layerToDelete.workspace_name &&
        element.policy_id == layerToDelete.policy_id &&
        element.layer_name == layerToDelete.layer_name
      ) {
        const index = state.DBlayers.indexOf(element);
        state.DBlayers.splice(index, 1);
      }
    });
  },

  GET_DOMAINS_WS(state, domainsArray) {
    domainsArray.forEach(elem => {
      state.domainsWS.push(elem);
    });
  },

  GET_DOMAINS_BY_WS(state, domainsArray) {
    domainsArray.forEach(elem => {
      state.wsDomains.push(elem);
    });
  },

  ADD_DOMAIN(state, wsDomain) {
    state.wsDomains.push(wsDomain);
  },

  DOMAIN_TO_ADD(state, wsDomain) {
    state.wsDomainsToAdd.push(wsDomain);
  },
  DOMAIN_TO_REMOVE(state, wsDomain) {
    state.wsDomainsToAdd = [
      ...state.wsDomainsToAdd.filter(el => {
        if (el.domain_id !== wsDomain.domain_id) {
          return el;
        }
      })
    ];
  },
  DELETE_DOMAIN(state, wsDomain) {
    state.wsDomains.forEach(element => {
      if (element.domain_id == wsDomain.domain_id) {
        const index = state.wsDomains.indexOf(element);
        state.wsDomains.splice(index, 1);
      }
    });
  },
  SWITCH_IS_MODIFING_WORKSPACE(state, data) {
    state.isModifingWorkspace = data;
  }
};

// ------------------------ ACTIONS ---------------------------

export const actions = {
  storeIsModifingWorkspace({ commit }, data) {
    commit('SWITCH_IS_MODIFING_WORKSPACE', data);
  },
  // GEOSERVER
  async storeWorkspaces({ dispatch, commit }) {
    try {
      const resp = await getWorkspaces();
      commit('GET_WORKSPACES', resp.data.workspaces.workspace);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeWorkspaces',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async storeWSLayers({ dispatch, commit }, workspace) {
    try {
      if (!Array.isArray(workspace)) {
        const resp = await getWSLayers(workspace);
        commit('GET_LAYERS', resp.data.layers.layer);
      } else {
        const layersObject = [];
        workspace.forEach(async e => {
          const resp = await getWSLayers(e.name);
          layersObject.push({
            workspace_name: e.name,
            layers: resp.data.layers.layer
          });
          commit('SET_LAYERS_OBJECT', layersObject);
        });
      }
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeWSLayers',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  deleteLayers({ commit }, layersArray) {
    commit('DELETE_LAYERS', layersArray);
  },

  addLayers({ commit }, layersArray) {
    commit('ADD_LAYERS', layersArray);
  },

  //APPSYNC
  insertWorkspaceName({ commit }, wsName) {
    commit('INSERT_WS_NAME', wsName);
  },

  updateLayerDBTable({ state, dispatch }) {
    state.geoserverLayers.forEach(dLayer => {
      dispatch('deleteSelectorLayer', dLayer);
    });
    state.chosenLayers.forEach(cLayer => {
      dispatch('storeCreatedLayer', cLayer);
    });
    dispatch(
      'storeToastMessage',
      {
        text: 'layer updated',
        type: 'success'
      },
      { root: true }
    );
  },

  async storeWorkspace({ commit }, ws) {
    commit('STORE_WS', ws);
  },

  async storeWorkspacesByPolicy({ dispatch, commit }, policy_id) {
    try {
      const ws = await getWorkspacesByPolicy(policy_id);
      commit('GET_WS_BY_POLICY', ws.data.listWorkspacesByPolicy);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeWorkspacesByPolicy',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async storeNewWorkspace({ rootState, state, dispatch, commit }) {
    state.workspace.n_calls = state.callsNum;
    state.workspace.start_time = state.startTime;
    state.workspace.stop_time = state.stopTime;
    state.workspace.date_type = state.dateType;
    state.workspace.policy_id = rootState.customerCRMID.policy_id;

    try {
      const wsToAdd = await newWorkspace(
        state.workspace.workspace_name,
        state.workspace.n_calls,
        state.workspace.start_time,
        state.workspace.stop_time,
        state.workspace.date_type,
        state.workspace.policy_id
      );

      for await (const el of state.wsDomainsToAdd) {
        try {
          const domainToAdd = await addDomain(
            el.workspace_name,
            el.policy_id,
            el.domain_id
          );
          commit('ADD_DOMAIN', domainToAdd.data.addDomainToWS);
        } catch (err) {
          dispatch(
            'storeToastMessage',
            {
              text: 'storeNewWorkspace',
              errObj: err,
              type: 'danger'
            },
            { root: true }
          );
        }
      }

      state.wsDomainsToAdd = [];
      dispatch(
        'storeToastMessage',
        {
          text: 'workspace created',
          type: 'success'
        },
        { root: true }
      );
      commit('NEW_WS', wsToAdd);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeNewWorkspace',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async storeUpdatedWorkspace({ rootState, state, dispatch, commit }) {
    state.workspace.n_calls = state.callsNum;
    state.workspace.start_time = state.startTime;
    state.workspace.stop_time = state.stopTime;
    state.workspace.date_type = state.dateType;
    state.workspace.policy_id = rootState.customerCRMID.policy_id;

    try {
      const wsToUpdate = await updateWorkspace(
        state.workspace.workspace_name,
        state.workspace.n_calls,
        state.workspace.start_time,
        state.workspace.stop_time,
        state.workspace.date_type,
        state.workspace.policy_id
      );

      if (state.wsDomainsToAdd.length) {
        for await (const el of state.wsDomainsToAdd) {
          try {
            const domainToAdd = await addDomain(
              el.workspace_name,
              el.policy_id,
              el.domain_id
            );
            commit('ADD_DOMAIN', domainToAdd.data.addDomainToWS);
          } catch (err) {
            dispatch(
              'storeToastMessage',
              {
                text: 'storeUpdatedWorkspace for loop',
                errObj: err,
                type: 'danger'
              },
              { root: true }
            );
          }
        }
      }

      state.wsDomainsToAdd = [];
      dispatch(
        'storeToastMessage',
        {
          text: 'workspace updated',
          type: 'success'
        },
        { root: true }
      );
      commit('UPDATE_WS', wsToUpdate.data.updateWorkspace);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeUpdatedWorkspace',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async deleteScrollWorkspace({ dispatch, commit }, ws) {
    try {
      const wsToDelete = await deleteWorkspace(ws.workspace_name, ws.policy_id);
      dispatch(
        'storeToastMessage',
        {
          text: 'workspace deleted',
          type: 'success'
        },
        { root: true }
      );
      commit('DELETE_WS', wsToDelete.data.deleteWorkspace);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'deleteScrollWorkspace',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async storeDBLayers({ rootState, state, dispatch, commit }) {
    try {
      const layersArray = await getDBLayers(
        state.workspace.workspace_name,
        rootState.customerCRMID.policy_id
      );
      commit('GET_DB_LAYERS', layersArray.data.listDBLayers);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeDBLayers',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async storeCreatedLayer(
    { rootState, state, dispatch, commit },
    createdLayer
  ) {
    try {
      const layerToAdd = await createLayer(
        state.workspace.workspace_name,
        rootState.customerCRMID.policy_id,
        createdLayer
      );
      commit('ADD_DB_LAYER', layerToAdd.data.createLayer);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeCreatedLayer',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async deleteSelectorLayer({ rootState, state, commit }, delLayer) {
    try {
      const layerToDelete = await deleteLayer(
        state.workspace.workspace_name,
        rootState.customerCRMID.policy_id,
        delLayer
      );
      commit('DELETE_DB_LAYER', layerToDelete.data.deleteLayer);
    } catch (err) {
      // dispatch(
      //   "storeToastMessage",
      //   {
      //     text: error,
      //     type: "danger"
      //   },
      //   { root: true }
      // );
    }
  },

  async storeDomainsWS({ dispatch, commit }) {
    try {
      const domainsArray = await getDomainsWS();
      commit('GET_DOMAINS_WS', domainsArray.data.listDomains);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeDomainsWS',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async storeDomainsByWS({ rootState, state, dispatch, commit }) {
    try {
      const domainsArray = await getDomainsByWS(
        state.workspace.workspace_name,
        rootState.customerCRMID.policy_id
      );
      commit('GET_DOMAINS_BY_WS', domainsArray.data.listDomainsByWorkspace);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeDomainsByWS',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async deleteDomainFromList({ rootState, state, dispatch, commit }, domain) {
    try {
      commit('DOMAIN_TO_REMOVE', {
        workspace_name: state.workspace.workspace_name,
        policy_id: rootState.customerCRMID.policy_id,
        domain_id: domain.id,
        domain_name: domain.name
      });
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'deleteDomainFromList',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async storeAddedDomain({ rootState, state, dispatch, commit }, domain) {
    try {
      commit('DOMAIN_TO_ADD', {
        workspace_name: state.workspace.workspace_name,
        policy_id: rootState.customerCRMID.policy_id,
        domain_id: domain.id,
        domain_name: domain.name
      });
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeAddedDomain',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },

  async deleteListDomain({ rootState, state, dispatch, commit }, domainID) {
    try {
      const domainToAdd = await deleteDomainWS(
        state.workspace.workspace_name,
        rootState.customerCRMID.policy_id,
        domainID.domain_id
      );
      dispatch(
        'storeToastMessage',
        {
          text: 'domain deleted',
          type: 'success'
        },
        { root: true }
      );
      commit('DELETE_DOMAIN', domainToAdd.data.deleteDomainFromWS);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'deleteListDomain',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  }
};

export const getters = {};
