<template>
  <v-container>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-stepper
      v-model="e1"
      class="ma-5"
    >
      <v-stepper-header class="full-height-class no-flex-wrap">
        <v-stepper-step
          :complete="e1 > 1"
          step="1"
          dark
          color="color_green"
        >
          Basic service dettails summary
        </v-stepper-step>

        <v-divider />

        <v-stepper-step
          v-if="service.service_type == 'DOC' || service.service_type == 'URL'"
          :complete="e1 > 2"
          step="2"
          dark
          color="color_green"
        >
          Add nodes to allow
        </v-stepper-step>

        <v-stepper-step
          v-if="service.service_type == 'WS'"
          :complete="e1 > 2"
          step="2"
          dark
          color="color_green"
        >
          Select workspace to allow
        </v-stepper-step>

        <v-divider />

        <v-stepper-step
          v-if="service.service_type == 'WS'"
          :complete="e1 > 3"
          step="3"
          dark
          color="color_green"
        >
          Enable global layers
        </v-stepper-step>

        <v-divider />

        <v-stepper-step
          v-if="service.service_type == 'WS'"
          :complete="e1 > 4"
          step="4"
          dark
          color="color_green"
        >
          Set time range
        </v-stepper-step>

        <v-divider />

        <v-stepper-step
          v-if="service.service_type == 'WS'"
          :complete="e1 > 5"
          step="5"
          dark
          color="color_green"
        >
          Set territorial domain
        </v-stepper-step>

        <v-divider />

        <v-stepper-step
          v-if="service.service_type == 'WS'"
          :complete="e1 > 6"
          step="6"
          dark
          color="color_green"
        >
          Select type of views
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card
            class="mb-12 elevation-0 pa-10 fill-height"
            color="blue-grey lighten-5"
          >
            <v-card>
              <v-card-title class="subheading font-weight-bold">
                {{ policy.blankPolicy.policy_name }}
              </v-card-title>

              <v-divider />
              <v-card-text>
                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>Policy ID:</v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{ policy.blankPolicy.policy_id }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>Service Name:</v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{ service.service_name }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>Service ID:</v-list-item-content>
                    <v-list-item-content class="align-end">
                      {{ service.service_id }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
            <v-card class="mt-4">
              <v-card-title> Policy Informations </v-card-title>
              <v-divider />
              <v-card-text>
                <b>Italiano:</b>
                <v-textarea
                  v-model="info"
                  outlined
                  hide-details
                  prepend-icon="mdi-information-variant-circle-outline"
                  append-icon="mdi-google-translate"
                  label="Policy Informations"
                  rows="3"
                  class="mt-3"
                  @click:append="isTranslatingInfo = true"
                />
                <v-card
                  v-if="isTranslatingInfo"
                  class="mt-4"
                >
                  <!-- <v-card-title>Translating tab</v-card-title>
                  <v-divider />-->
                  <v-card-text>
                    <v-select
                      v-model="selectedLanguage"
                      label="Select Language"
                      :items="setLanguages"
                      :value="setLanguages.text"
                      @change="changeSelectedLanguage()"
                    />
                    <div
                      v-if="selectedLanguage"
                      class="d-flex justify-end flex-column"
                    >
                      <v-textarea
                        v-model="info_translation"
                        outlined
                        hide-details
                        prepend-icon="mdi-information-variant-circle-outline"
                        :label="selectedLanguage + ' translation'"
                      />
                      <v-btn
                        class="mt-2"
                        color="success"
                        @click="updateTranslatedInfo()"
                      >
                        CONFIRM
                      </v-btn>
                    </div>
                  </v-card-text>
                </v-card>
              </v-card-text>
            </v-card>
          </v-card>
          <v-row class="pa-5">
            <v-col
              cols="5"
              class="text-center"
            >
              <v-btn
                color="error"
                @click="cancellEvent('app-policy')"
              >
                Cancel
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col
              cols="5"
              class="text-center"
            >
              <v-btn
                color="primary"
                @click="e1 = 2, updateInfo()"
              >
                Continue
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card
            class="mb-12 elevation-0 pa-10 fill-height"
            color="blue-grey lighten-5"
          >
            <v-row>
              <v-col>
                <v-card
                  v-if="
                    service &&
                      (service.service_type == 'URL' ||
                        service.service_type == 'DOC')
                  "
                >
                  <v-list dense>
                    <v-subheader>Nodes to add:</v-subheader>
                    <v-list-item
                      v-for="(item, i) in policy.nodeToAdd"
                      :key="i"
                    >
                      <v-list-item-content class="d-block">
                        <v-row>
                          <v-col>
                            <v-list-item-title>
                              {{ item.node_name }}
                            </v-list-item-title>
                          </v-col>
                          <v-col>
                            <v-list-item-subtitle>
                              {{ item.external_link || item.path_s3_bucket }}
                            </v-list-item-subtitle>
                          </v-col>
                          <v-col align="right">
                            <v-btn icon>
                              <delete
                                :id="item.node_name"
                                @deleted="nodeToDelete(item, service)"
                              />
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
                <v-card outlined>
                  <v-toolbar
                    flat
                    dark
                    color="color_green"
                  >
                    <v-toolbar-title>
                      Configuration Policy Section
                    </v-toolbar-title>
                    <v-divider
                      class="mx-4"
                      inset
                      vertical
                    />
                    <v-spacer />
                  </v-toolbar>
                  <v-card-text>
                    <configuration-url-component
                      v-if="service && service.service_type == 'URL'"
                      :service="service"
                    />
                    <configuration-documental-component
                      v-if="service && service.service_type == 'DOC'"
                      :service="service"
                    />
                    <configuration-workspace-component
                      v-if="service && service.service_type == 'WS'"
                      :service="service"
                    />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card>

          <v-row class="pa-5">
            <v-col
              cols="5"
              class="text-center"
            >
              <v-btn
                color="error"
                @click="cancellEvent('app-policy')"
              >
                Cancel
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col
              cols="5"
              class="text-center"
            >
              <v-btn
                text
                @click="e1 = 1"
              >
                Return
              </v-btn>
              <v-btn
                v-if="
                  service.service_type !== 'URL' &&
                    service.service_type !== 'DOC'
                "
                color="primary"
                @click="e1 = 3, cleanSelectedLayer()"
              >
                Continue
              </v-btn>
              <v-btn
                v-if="
                  service.service_type == 'URL' || service.service_type == 'DOC'
                "
                color="primary"
                @click="confirmPolicy()"
              >
                Confirm
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card
            class="mb-12 elevation-0 pa-10"
            color="blue-grey lighten-5"
          >
            <div class="mx-auto mt-6 file-input-width">
              <v-data-table
                v-model="WSForm.globalLayers"
                :headers="layersHeaders"
                :items="layers.globalLayers"
                :single-select="false"
                item-key="global_layer_id"
                dense
                show-select
              />
            </div>
          </v-card>

          <v-row class="pa-5">
            <v-col
              cols="5"
              class="text-center"
            >
              <v-btn
                color="error"
                @click="cancellEvent('app-policy')"
              >
                Cancel
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col
              cols="5"
              class="text-center"
            >
              <v-btn
                text
                @click="e1 = 2"
              >
                Return
              </v-btn>
              <v-btn
                color="primary"
                @click="goToStep4()"
              >
                Continue
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
        <v-stepper-content step="4">
          <v-card
            class="mb-12 elevation-0 pa-10"
            color="blue-grey lighten-5"
          >
            <time-extension v-if="e1 === 4" />
          </v-card>

          <v-row class="pa-5">
            <v-col
              cols="5"
              class="text-center"
            >
              <v-btn
                color="error"
                @click="cancellEvent('app-policy')"
              >
                Cancel
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col
              cols="5"
              class="text-center"
            >
              <v-btn
                text
                @click="e1 = 3"
              >
                Return
              </v-btn>
              <v-btn
                color="primary"
                @click="e1 = 5"
              >
                Continue
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="5">
          <v-card
            class="mb-12 elevation-0 pa-10"
            color="blue-grey lighten-5"
          >
            <v-card outlined>
              <v-toolbar
                flat
                dark
                color="color_green"
              >
                <v-spacer />
                <v-toolbar-title> Domains </v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-divider />
            </v-card>
            <v-card-actions>
              <v-row>
                <v-col cols="10">
                  <v-autocomplete
                    id="domain_id"
                    v-model="domainToAdd"
                    label="Domain"
                    :items="geoserver_data.domainsWS"
                    item-text="domain_name"
                    :search-input.sync="searchDomainAutocomplete"
                    :filter="customFilter"
                    color="color_green"
                    outlined
                    hint="Click the icon to add"
                    return-object
                  >
                    <template v-slot:selection="{ item }">
                      <span
                        class="d-inline-block text-truncate"
                        style="max-width: 30em"
                      >
                        {{ item.domain_id }} - {{ item.domain_name }}
                      </span>
                    </template>
                    <template v-slot:[`item`]="{ item }">
                      <span
                        class="d-inline-block text-truncate"
                        style="max-width: 30em"
                      >
                        {{ item.domain_id }} - {{ item.domain_name }}
                      </span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="2">
                  <v-btn
                    height="55"
                    color="primary"
                    @click="addNewDomain"
                  >
                    <v-icon large>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>

            <v-virtual-scroll
              :items="geoserver_data.wsDomainsToAdd"
              bench="5"
              max-height="300"
              item-height="64"
            >
              <template v-slot:default="{ item }">
                <v-list-item :key="item.domain_name">
                  <v-list-item-content>
                    <v-list-item-title>
                      Domain: {{ item.domain_id }} - {{ item.domain_name }}
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn icon>
                      <delete
                        :id="item.domain_name"
                        @deleted="deleteDomainWS(item)"
                      />
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-virtual-scroll>
          </v-card>

          <v-row class="pa-5">
            <v-col
              cols="5"
              class="text-center"
            >
              <v-btn
                color="error"
                @click="cancellEvent('app-policy')"
              >
                Cancel
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col
              cols="5"
              class="text-center"
            >
              <v-btn
                text
                @click="e1 = 4"
              >
                Return
              </v-btn>
              <v-btn
                color="primary"
                :disabled="geoserver_data.wsDomainsToAdd.length === 0"
                @click="e1 = 6"
              >
                Continue
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <v-stepper-content step="6">
          <div class="d-flex justify-space-between">
            <div class="ma-6 file-input-width">
              <v-checkbox
                v-if="wsService.wsTemplate.is_map"
                v-model="is_map"
                label="Map"
              />
              <v-checkbox
                v-if="wsService.wsTemplate.is_animation"
                v-model="is_animation"
                label="Animation"
              />
              <v-checkbox
                v-if="wsService.wsTemplate.is_graph"
                v-model="is_graph"
                label="Graph"
              />
              <v-checkbox
                v-if="wsService.wsTemplate.is_table"
                v-model="is_table"
                label="Table"
              />
              <v-checkbox
                v-if="wsService.wsTemplate.is_meteogram"
                v-model="is_meteogram"
                label="Meteogram"
              />
            </div>
            <v-spacer />
            <configuration-meteogram
              v-if="is_meteogram"
              :meteograms="meteograms"
              :to-delete="meteogramsToDelete"
              @delete-all="meteograms = []"
            />
            <v-spacer />
          </div>

          <v-row class="pa-5">
            <v-col
              cols="5"
              class="text-center"
            >
              <v-btn
                color="error"
                @click="cancellEvent('app-policy')"
              >
                Cancel
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col
              cols="5"
              class="text-center"
            >
              <v-btn
                text
                @click="e1 = 5"
              >
                Return
              </v-btn>
              <v-btn
                color="primary"
                @click="confirmPolicy()"
              >
                Continue
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { getLayerInfo } from '@/api/services';
import { log, LANGUAGES } from '@/constants.js';

export default {
  components: {
    configurationUrlComponent: () =>
      import('@/components/policy/ConfigurationUrlPolicy'),
    configurationDocumentalComponent: () =>
      import('@/components/policy/ConfigurationDocumentalPolicy'),
    configurationWorkspaceComponent: () =>
      import('@/components/policy/ConfigurationWorkspacePolicy'),
    configurationMeteogram: () =>
      import('@/components/policy/ConfigurationMeteogram'),
    delete: () => import('@/components/utils/Delete'),
    TimeExtension: () => import('@/components/policy/TimeExtension')
  },
  data() {
    return {
      info: '',
      info_translation: '',
      isTranslatingInfo: false,
      selectedLanguage: '',
      searchDomainAutocomplete: '',
      e1: 1,
      service: null,
      pickerDate: null,
      domainToAdd: null,
      overlay: false,
      menu: false,
      menu2: false,
      menu3: false,
      is_map: false,
      is_animation: false,
      is_graph: false,
      is_table: false,
      is_meteogram: false,
      layersHeaders: [],
      meteograms: [],
      meteogramsToDelete: [],
      WSForm: {
        service_id: '',
        ws_name: [],
        ws_layers: [],
        globalLayers: [],
        is_graph: false,
        is_animation: false,
        is_table: false,
        is_meteogram: false,
        is_map: false,
        info: []
      }
    };
  },
  computed: {
    ...mapState(['services', 'wsService', 'policy', 'geoserver_data', 'layers']),
    ...mapGetters(['getServiceById', 'getHeadersWithoutActions']),
    setLanguages() {
      return LANGUAGES;
    }
  },
  async beforeMount() {
    this.overlay = true;
    const tmp = this.getServiceById(this.services.service_id);
    this.service = tmp[0];

    if (!this.service) {
      this.$router.push('/');
    } else {
      await this.storeDomainsWS();

      if (this.service.service_type == 'URL') {
        const data = {
          service_id: this.service.service_id,
          policy_id: this.policy.blankPolicy.policy_id,
          service_type: this.service.service_type
        };
        await this.storeListConfigurationPolicy(data);
        await this.storeGetUrlServiceConfiguration(this.services.service_id);
      } else {
        if (this.service.service_type == 'DOC') {
          const data = {
            service_id: this.service.service_id,
            policy_id: this.policy.blankPolicy.policy_id,
            service_type: this.service.service_type
          };
          await this.storeListConfigurationPolicy(data);
          await this.storeGetDocumentalServiceConfiguration(this.services.service_id);
        } else {
          if (this.service.service_type == 'WS') {
            await this.getWorkspaceConfigurationAction(this.services.service_id);
            this.layersHeaders = this.getHeadersWithoutActions();

            for await (const item of this.wsService.wsTemplate.ws_layers) {
              const layer = await getLayerInfo(item.ws_name, item.name);
              const styles = ['Default', layer.defaultStyle.name];
              const layerStyles = layer.styles?.style;

              if (layerStyles) {
                const layerStyleNames = Array.isArray(layerStyles)
                  ? layerStyles.map(e => e.name)
                  : [layerStyles.name];

                styles.push(...layerStyleNames);
              }

              Object.assign(item, {
                styles: [...new Set(styles)],
                defaultStyle: layer.defaultStyle.name,
                layer_style: 'Default'
              });
            }

            await this.storeGlobalLayers([this.service.service_id]);
            this.WSForm = this.wsService.wsTemplate;

            await this.getPolicyConfiguration();
            this.WSForm.info = this.policy.wsConfigurationPolicy.info;
            this.info = this.WSForm.info?.find(e => e.lang === 'it')?.text || '';
          }
        }
      }
    }

    this.overlay = false;
  },
  methods: {
    ...mapActions([
      'configurationUrlServicesPolicyAction',
      'configurationDocumentalServicesPolicyAction',
      'storeDomainsWS',
      'storeAddedDomain',
      'deleteDomainFromList',
      'storeGetUrlServiceConfiguration',
      'storeGetDocumentalServiceConfiguration',
      'getWorkspaceConfigurationAction',
      'configurationWorkspaceServicesPolicyAction',
      'storeGlobalLayers',
      'storeListConfigurationPolicy',
      'storeConfigurationWorkspacePolicy',
      'updateWorkspaceServicesPolicyAction',
      'deleteDocumentalUrlNodeAction'
    ]),
    async getPolicyConfiguration() {
      this.wsService.selectedWs = [];
      this.WSForm.globalLayers = [];
      this.geoserver_data.workspace.date_type = '';
      this.geoserver_data.workspace.start_time = '';
      this.geoserver_data.workspace.stop_time = '';
      this.geoserver_data.wsDomainsToAdd = [];

      if (this.policy.blankPolicy.pws_id != null) {
        const data = {
          pws_id: this.policy.blankPolicy.pws_id,
          service_id: this.policy.blankPolicy.service_id,
          policy_id: this.policy.blankPolicy.policy_id

        };
        await this.storeConfigurationWorkspacePolicy(data);

        if (this.policy.wsConfigurationPolicy.ws_name.length > 0) {
          this.policy.wsConfigurationPolicy.ws_name.forEach(ws => {
            this.policy.wsConfigurationPolicy.layers.forEach(layer => {
              const selectStyle = this.WSForm.ws_layers.find(e => e.ws_id === layer.ws_id && e.name === layer.layer_name);

              if (selectStyle) {
                selectStyle.layer_style = layer.layer_style === '' ? 'Default' : layer.layer_style;
              }

              if (ws.ws_id === layer.ws_id) {
                this.wsService.selectedWs.findIndex(item => item.ws_id === ws.ws_id && item.ws_group === ws.ws_group && item.name === layer.layer_name && item.ws_name === ws.ws_name) === -1 ?
                  this.wsService.selectedWs.push({ ws_id: ws.ws_id, ws_group: ws.ws_group, name: layer.layer_name, pws_id: ws.pws_id, ws_name: ws.ws_name })
                  : log.error('item exist');
              }
            });
          });
          this.WSForm.globalLayers = this.policy.wsConfigurationPolicy.globalLayers;

          this.geoserver_data.isModifingWorkspace = true;
          this.geoserver_data.workspace.date_type = this.policy.wsConfigurationPolicy.date_type;
          this.geoserver_data.workspace.start_time = this.policy.wsConfigurationPolicy.start_date;
          this.geoserver_data.workspace.stop_time = this.policy.wsConfigurationPolicy.end_date;

          this.geoserver_data.wsDomainsToAdd = this.policy.wsConfigurationPolicy.domains;

          this.is_map = this.policy.wsConfigurationPolicy.is_map;
          this.is_animation = this.policy.wsConfigurationPolicy.is_animation;
          this.is_graph = this.policy.wsConfigurationPolicy.is_graph;
          this.is_table = this.policy.wsConfigurationPolicy.is_table;
          this.is_meteogram = this.policy.wsConfigurationPolicy.is_meteogram;

          this.meteograms = [...this.policy.wsConfigurationPolicy.meteograms];
        }
      }
    },
    async confirmPolicy() {
      this.overlay = true;
      this.updateInfo();

      if (this.service.service_type == 'URL') {
        if (this.policy.storeConfigurationPolicy.length > 0) {
          const storeConfigurationPolicyLength = this.policy.storeConfigurationPolicy.length;
          this.policy.nodeToAdd.splice(0, storeConfigurationPolicyLength);
        }

        const mappedElementForCall = this.policy.nodeToAdd.map(e => {
          return {
            service_id: e.service_id,
            policy_id: this.policy.blankPolicy.policy_id,
            node_id: e.node_id,
            external_link: e.external_link
          };
        });

        if (this.policy.nodeToDelete.length > 0) {
          for await (const node of this.policy.nodeToDelete) {
            await this.deleteDocumentalUrlNodeAction(node);
          }
        }

        this.policy.nodeToDelete = [];
        await this.configurationUrlServicesPolicyAction(mappedElementForCall);
      } else {
        if (this.service.service_type == 'DOC') {
          if (this.policy.storeConfigurationPolicy.length > 0) {
            const storeConfigurationPolicyLength = this.policy.storeConfigurationPolicy.length;
            this.policy.nodeToAdd.splice(0, storeConfigurationPolicyLength);
          }

          const mappedElementForCall = this.policy.nodeToAdd.map(e => {
            return {
              service_id: e.service_id,
              policy_id: this.policy.blankPolicy.policy_id,
              node_id: e.node_id,
              path_s3_bucket: e.path_s3_bucket
            };
          });

          if (this.policy.nodeToDelete.length > 0) {
            for await (const node of this.policy.nodeToDelete) {
              await this.deleteDocumentalUrlNodeAction(node);
            }
          }

          this.policy.nodeToDelete = [];
          await this.configurationDocumentalServicesPolicyAction(mappedElementForCall);
        } else {
          if (this.service.service_type == 'WS') {
            if (this.e1 == 2) {
              this.e1 = 3;
            } else {
              const mappedElementForCall = {
                pws_id: this.policy.blankPolicy.pws_id || '',
                policy_id: this.policy.blankPolicy.policy_id,
                service_id: this.services.service_id,
                is_graph: this.is_graph,
                is_animation: this.is_animation,
                is_table: this.is_table,
                is_meteogram: this.is_meteogram,
                is_map: this.is_map,
                start_date: this.geoserver_data.startTime,
                end_date: this.geoserver_data.stopTime,
                date_type: this.geoserver_data.dateType,
                ws_name: [],
                layers: this.wsService.selectedWs,
                domains: this.geoserver_data.wsDomainsToAdd,
                globalLayers: this.WSForm.globalLayers,
                meteograms: this.meteograms,
                meteogramsToDelete: this.meteogramsToDelete,
                info: this.WSForm.info
              };
              this.wsService.selectedWs.forEach(layer => {
                delete layer.udm;
                delete layer.layer_label;
                delete layer.layer_color;
                const selectStyle = this.WSForm.ws_layers.find(e => e.ws_id === layer.ws_id && e.name === layer.name);
                layer.layer_style = selectStyle.layer_style === 'Default' ? '' : selectStyle.layer_style;
                delete layer.defaultStyle;
                delete layer.styles;
                mappedElementForCall.ws_name.findIndex(item => item.ws_group === layer.ws_group && item.ws_id === layer.ws_id) === -1 ?
                  mappedElementForCall.ws_name.push({ ws_id: layer.ws_id, ws_group: layer.ws_group, name: layer.ws_name }) : log.error('item exist');
              });

              if (this.policy.blankPolicy.policy_state === 'DRAFT') {
                await this.configurationWorkspaceServicesPolicyAction(
                  mappedElementForCall
                );
              } else {
                await this.updateWorkspaceServicesPolicyAction(
                  mappedElementForCall
                );
              }
            }
          }
        }
      }

      this.cancellEvent('app-policy');
      this.overlay = false;
    },
    cancellEvent(route) {
      this.policy.nodeToAdd = [];
      this.domainToAdd = '';
      this.wsService.selectedWs = [];
      this.e1 = 1;
      this.service = null;
      this.pickerDate = null;
      this.domainToAdd = null;
      this.geoserver_data.wsDomainsToAdd = [];
      this.searchDomainAutocomplete = '';
      this.menu = false;
      this.menu2 = false;
      this.menu3 = false;
      this.$router.push(route);
    },
    async addNewDomain() {
      await this.storeAddedDomain({
        id: this.domainToAdd.domain_id,
        name: this.domainToAdd.domain_name
      });
    },
    async goToStep4() {
      this.e1 = 4;
    },
    async deleteDomainWS(item) {
      await this.deleteDomainFromList({
        id: item.domain_id,
        name: item.domain_name
      });
    },
    async nodeToDelete(item, service) {
      const nodeToDelete = {
        service_id: item.service_id,
        policy_id: item.policy_id || this.policy.blankPolicy.policy_id,
        node_id: item.node_id,
        service_type: service.service_type
      };
      this.policy.nodeToDelete.push(nodeToDelete);
      this.policy.nodeToAdd = this.policy.nodeToAdd.filter(el => el.node_id !== item.node_id);
    },
    customFilter (item, queryText) {
      const textOne = item.domain_name.toLowerCase();
      const textTwo = item.domain_id.toString();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
    },
    updateInfo() {
      this.WSForm.info = this.WSForm.info?.filter(e => e.lang !== 'it') || [];
      this.WSForm.info.push({ lang: 'it', text: this.info.trim() });
    },
    updateTranslatedInfo() {
      try {
        const LANG = this.selectedLanguage.split('-')[0];
        this.WSForm.info = this.WSForm.info.filter(e => e.lang !== LANG);
        this.WSForm.info.push({ lang: LANG, text: this.info_translation.trim() });
        this.$store.dispatch(
          'storeToastMessage',
          {
            text: 'Translation added!',
            type: 'success'
          },
          { root: true }
        );
      } catch (err) {
        this.$store.dispatch(
          'storeToastMessage',
          {
            text: 'Something went wrong while trying to add translation.',
            errObj: err,
            type: 'danger'
          },
          { root: true }
        );
      }
    },
    changeSelectedLanguage() {
      const LANG = this.selectedLanguage.split('-')[0];
      this.info_translation = this.WSForm.info?.find(e => e.lang === LANG)?.text || [];
    },
    cleanSelectedLayer() {
      // Rimuove i Layer che erano selezionati e ora non sono più presenti nel servizio
      this.wsService.selectedWs.forEach(selectedLayer => {
        if (
          this.wsService.wsTemplate.ws_layers.findIndex(layer => layer.name === selectedLayer.name) === -1
        ) {
          const index = this.wsService.selectedWs.indexOf(selectedLayer);
          this.wsService.selectedWs.splice(index, 1);
        }
      });
    }
  }
};
</script>

<style scoped>
.full-height {
  height: 100%;
}
</style>
