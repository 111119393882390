<template>
  <v-container>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>

    <v-form>
      <v-stepper
        id="workspace-stepper"
        v-model="e6"
      >
        <v-stepper-header class="full-height-class no-flex-wrap">
          <v-stepper-step
            :complete="e6 > 1"
            step="1"
            dark
            color="color_green"
          >
            Select workspaces
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            :complete="e6 > 2"
            step="2"
            dark
            color="color_green"
          >
            Select group and layer
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            :complete="e6 > 3"
            step="3"
            dark
            color="color_green"
          >
            Set layers data
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            :complete="e6 > 4"
            step="4"
            dark
            color="color_green"
          >
            Select type of views
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            :complete="e6 > 5"
            step="5"
            dark
            color="color_green"
          >
            Specific Group configuration
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            :complete="e6 > 6"
            step="6"
            dark
            color="color_green"
          >
            Enable generic layer
          </v-stepper-step>
          <v-divider />
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card
              class="mx-auto elevation-0 pa-5 file-input-width"
              color="blue-grey lighten-5"
            >
              <v-card>
                <v-card-title>
                  Select store
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <v-data-table
                    v-model="wsService.selectedWsConfiguration"
                    :items="geoserver_data.cleanWorkspaces"
                    :search="search"
                    :headers="geoserver_data.headers"
                    :single-select="false"
                    item-key="name"
                    show-select
                    class="elevation-0"
                    dense
                  >
                    <template v-slot:top>
                      <v-toolbar
                        flat
                        color="white"
                      >
                        <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Search"
                          single-line
                          hide-details
                        />
                      </v-toolbar>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>

              <v-card class="mt-5">
                <v-card-title>Application info</v-card-title>
                <v-divider />
                <v-card-text>
                  <b>Italiano: </b>
                  <v-textarea
                    v-model="info"
                    outlined
                    prepend-icon="mdi-information-variant-circle-outline"
                    append-icon="mdi-google-translate"
                    label="Application Informations"
                    rows="3"
                    class="mt-3"
                    @click:append="isTranslatingInfo = true"
                  />
                  <v-card
                    v-if="isTranslatingInfo"
                    class="mt-4"
                  >
                    <!-- <v-card-title>Translating tab</v-card-title>
                    <v-divider />-->
                    <v-card-text>
                      <v-select
                        v-model="selectedLanguage"
                        label="Select Language"
                        :items="setLanguages"
                        :value="setLanguages.text"
                        @change="changeSelectedLanguage()"
                      />
                      <div
                        v-if="selectedLanguage"
                        class="d-flex justify-end flex-column"
                      >
                        <v-textarea
                          v-model="info_translation"
                          outlined
                          hide-details
                          prepend-icon="mdi-information-variant-circle-outline"
                          :label="selectedLanguage + ' translation'"
                        />
                        <v-btn
                          class="mt-2"
                          color="success"
                          @click="updateTranslatedInfo()"
                        >
                          CONFIRM
                        </v-btn>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-card-text>
              </v-card>
            </v-card>
            <v-row class="mt-6">
              <v-col
                cols="2"
                class="text-right"
              >
                <v-btn
                  dark
                  color="warning"
                  @click="closeAction('close')"
                >
                  Close
                </v-btn>
              </v-col>
              <v-spacer />
              <v-col
                cols="2"
                class="text-left"
              >
                <v-btn
                  dark
                  color="color_green"
                  @click="goToStep2()"
                >
                  Continue
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="2">
            <div class="mx-auto mt-6 file-input-width">
              <v-card class="mt-4">
                <v-row>
                  <v-col cols="5">
                    <v-list dense>
                      <v-card-title>layers:</v-card-title>
                      <v-expansion-panels>
                        <v-expansion-panel
                          v-for="(item,
                                  i) in geoserver_data.geoserverLayersObject"
                          :key="i"
                          color="color_green"
                        >
                          <v-expansion-panel-header>
                            {{ item.workspace_name }}
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-data-table
                              v-model="WSForm.ws_layers"
                              :items="item.layers"
                              :headers="geoserver_data.headers"
                              :single-select="false"
                              item-key="name"
                              show-select
                              class="elevation-0 pa-5"
                              dense
                            />

                            <v-divider />

                            <v-row>
                              <v-col cols="8">
                                <v-autocomplete
                                  v-model="groupToAdd"
                                  label="Groups"
                                  :items="groups"
                                  :search-input.sync="searchGroup"
                                  color="color_green"
                                  outlined
                                  hint="Click the icon to select"
                                  class="mt-5"
                                />
                              </v-col>
                              <v-col
                                cols="3"
                                class="mt-5"
                              >
                                <v-btn
                                  height="55"
                                  color="primary"
                                  @click="selectGroup(item)"
                                >
                                  <v-icon large>
                                    mdi-plus
                                  </v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-list>
                  </v-col>
                  <v-col cols="6">
                    <v-list dense>
                      <v-card-title>layers added:</v-card-title>
                      <v-data-table
                        :items.sync="wsLayersSelected"
                        :headers="geoserver_data.layerHeaders"
                        item-key="id"
                        class="elevation-0 pa-5"
                        dense
                      >
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-icon
                            small
                            @click="remove(item)"
                          >
                            mdi-delete
                          </v-icon>
                        </template>
                      </v-data-table>
                    </v-list>
                  </v-col>
                </v-row>
              </v-card>
            </div>
            <v-row class="mt-6">
              <v-col
                cols="2"
                class="text-right"
              >
                <v-btn
                  dark
                  color="warning"
                  @click="closeAction('close')"
                >
                  Close
                </v-btn>
              </v-col>
              <v-spacer />
              <v-col
                cols="4"
                class="d-flex text-left"
              >
                <v-btn
                  class="mr-2"
                  text
                  @click="e6 = 1"
                >
                  Return
                </v-btn>
                <v-btn
                  dark
                  color="color_green"
                  @click="e6 = 3"
                >
                  Continue
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="3">
            <div class="mx-auto mt-6 file-input-width">
              <v-data-table
                :items="wsLayersSelected"
                :headers="layerDataHeaders"
                item-key="name"
                class="elevation-0 pa-5"
                dense
              >
                <template v-slot:[`item.layer_color`]="{ item }">
                  <div
                    id="layerColor"
                    :style="`background-color: ${item.layer_color}`"
                  />
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <layers-data-popup
                    :item="item"
                    :service-id="WSForm.service_id"
                  />
                  <v-icon
                    small
                    class="ml-1"
                    @click="openTranslationTab(item)"
                  >
                    mdi-google-translate
                  </v-icon>
                </template>
              </v-data-table>
            </div>
            <v-row class="mt-6">
              <v-col
                cols="2"
                class="text-right"
              >
                <v-btn
                  dark
                  color="warning"
                  @click="closeAction('close')"
                >
                  Close
                </v-btn>
              </v-col>
              <v-spacer />
              <v-col
                cols="4"
                class="d-flex text-left"
              >
                <v-btn
                  class="mr-2"
                  text
                  @click="e6 = 2"
                >
                  Return
                </v-btn>
                <v-btn
                  dark
                  color="color_green"
                  @click="e6 = 4; closeTranslationTab()"
                >
                  Continue
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="4">
            <div class="ma-6 file-input-width">
              <v-checkbox
                v-model="WSForm.is_map"
                label="Map"
              />
              <v-checkbox
                v-model="WSForm.is_animation"
                label="Animation"
              />
              <v-checkbox
                v-model="WSForm.is_graph"
                label="Graph"
              />
              <v-checkbox
                v-model="WSForm.is_table"
                label="Table"
              />
              <v-checkbox
                v-model="WSForm.is_meteogram"
                label="Meteogram"
              />
            </div>

            <v-row class="mt-6">
              <v-col
                cols="2"
                class="text-right"
              >
                <v-btn
                  dark
                  color="warning"
                  @click="closeAction('close')"
                >
                  Close
                </v-btn>
              </v-col>
              <v-spacer />
              <v-col
                cols="4"
                class="d-flex text-left"
              >
                <v-btn
                  class="mr-2"
                  text
                  @click="e6 = 3"
                >
                  Return
                </v-btn>
                <v-btn
                  dark
                  color="color_green"
                  @click="e6 = 5; updateInfo()"
                >
                  Continue
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-content
            step="5"
            class="pt-0"
          >
            <div
              v-if="e6 == 5"
              class="mx-auto file-input-width"
            >
              <configuration-specific-workspace-group
                :ws-layers-selected="wsLayersSelected"
                :ws-form="WSForm"
              />
            </div>
            <v-row class="mt-6">
              <v-col
                cols="2"
                class="text-right"
              >
                <v-btn
                  dark
                  color="warning"
                  @click="closeAction('close')"
                >
                  Close
                </v-btn>
              </v-col>
              <v-spacer />
              <v-col
                cols="4"
                class="d-flex text-left"
              >
                <v-btn
                  class="mr-2"
                  text
                  @click="e6 = 4"
                >
                  Return
                </v-btn>
                <v-btn
                  dark
                  color="color_green"
                  @click="e6 = 6"
                >
                  Continue
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="6">
            <div class="mx-auto mt-6 file-input-width">
              <v-data-table
                v-model="WSForm.globalLayers"
                :headers="layersHeaders"
                :items="layers.globalLayers"
                :single-select="false"
                item-key="global_layer_id"
                dense
                show-select
              />
            </div>
            <v-row class="mt-6">
              <v-col
                cols="2"
                class="text-right"
              >
                <v-btn
                  dark
                  color="warning"
                  @click="closeAction('close')"
                >
                  Close
                </v-btn>
              </v-col>
              <v-spacer />
              <v-col
                cols="4"
                class="d-flex text-left"
              >
                <v-btn
                  class="mr-2"
                  text
                  @click="e6 = 5"
                >
                  Return
                </v-btn>
              </v-col>
              <v-col cols="2">
                <v-btn
                  dark
                  color="color_green"
                  @click="confirmConfiguration()"
                >
                  Confirm
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-form>
    <layers-label-translation
      v-if="isTranslating"
      :layer="selectedLayer"
      :service-id="WSForm.service_id"
      @close-translation-tab="closeTranslationTab()"
    />
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { log, LANGUAGES } from '@/constants.js';
import { getLayerInfo } from '@/api/services';

export default {
  components: {
    ConfigurationSpecificWorkspaceGroup: () =>
      import('@/components/services/ConfigurationSpecificWorkspaceGroup'),
    LayersDataPopup: () => import('@/components/services/LayersDataPopup'),
    LayersLabelTranslation: () => import('@/components/services/LayersLabelTranslation')
  },
  data() {
    return {
      info: '',
      info_translation: '',
      isTranslatingInfo: false,
      selectedLanguage: '',
      search: '',
      searchGroup: '',
      e6: 1,
      groupToAdd: null,
      isTranslating: false,
      overlay: false,
      selectedLayer: {},
      WSForm: {
        service_id: '',
        ws_name: [],
        ws_layers: [],
        globalLayers: [],
        is_graph: false,
        is_animation: false,
        is_table: false,
        is_meteogram: false,
        is_map: false,
        info: []
      },
      groups: ['Archive', 'Real-Time', 'Forecast'],
      layersHeaders: [],
      layersToRemove: [],
      wsLayersSelected: [],
      wsLayersSelectedData: [],
      layerDataHeaders: [
        {
          text: 'Workspace Name',
          align: 'start',
          sortable: true,
          value: 'ws_name'
        },
        {
          text: 'Group',
          align: 'start',
          sortable: true,
          value: 'ws_group'
        },
        {
          text: 'Layer Name',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Label',
          align: 'start',
          sortable: true,
          value: 'layer_label'
        },
        {
          text: 'Udm',
          align: 'start',
          sortable: true,
          value: 'udm'
        },
        {
          text: 'Color',
          align: 'start',
          sortable: false,
          value: 'layer_color'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    };
  },
  computed: {
    ...mapState(['services', 'wsService', 'geoserver_data', 'layers']),
    ...mapGetters(['getHeadersWithoutActions']),
    setLanguages() {
      return LANGUAGES;
    }
  },
  watch: {
    $route() {},
    '$route.params': {
      handler(newValue) {
        if (newValue) {
          this.closeAction();
        }
      },
      immediate: true
    }
  },
  async created() {
    if (this.services.service_id == '') {
      this.$router.push('/');
    } else {
      this.overlay = true;
      this.WSForm = {};
      await this.storeWorkspaces();
      await this.storeGlobalLayers();
      await this.getWorkspaceConfigurationAction(this.services.service_id);
      await this.setWorkspaceForTemplate();
      this.layersHeaders = this.getHeadersWithoutActions();
      this.WSForm = this.wsService.wsTemplate;
      this.WSForm.service_id = this.services.service_id;
      this.WSForm.ws_layers.forEach(layer => {
        this.layersToRemove.push(layer);
        layer.languages = [];
      });
      this.wsLayersSelected = JSON.parse(JSON.stringify(this.WSForm.ws_layers));
      this.info = this.WSForm.info.find(e => e.lang === 'it')?.text || '';
      this.overlay = false;
    }
  },
  methods: {
    ...mapActions([
      'storeWorkspaces',
      'storeWSLayers',
      'storeGlobalLayers',
      'createWorkspaceServiceAction',
      'updateWorkspaceServiceAction',
      'getWorkspaceConfigurationAction',
      'setWorkspaceForTemplate',
      'storeServices'
    ]),
    closeAction(type) {
      this.WSForm = {
        service_id: '',
        ws_name: [],
        ws_layers: [],
        globalLayers: [],
        is_graph: false,
        is_animation: false,
        is_table: false,
        is_meteogram: false,
        is_map: false,
        info: []
      };
      this.wsService.wsTemplate = {
        service_id: '',
        ws_name: [],
        ws_layers: [],
        globalLayers: [],
        is_graph: false,
        is_animation: false,
        is_table: false,
        is_meteogram: false,
        is_map: false,
        info: []
      };

      if (type === 'close') {
        this.$router.push('/services');
      }
    },
    async confirmConfiguration() {
      this.overlay = true;
      const finder = this.wsLayersSelected.find(layer => layer.animation_step);

      for await (const ws of this.WSForm.ws_name) {
        ws.layers = [];
        delete ws.href;

        for await (const layer of this.WSForm.ws_layers) {
          const indexToRemove = this.layersToRemove.findIndex(currentItem => currentItem.name === layer.name && currentItem.ws_name === layer.ws_name && currentItem.ws_id === layer.ws_id);

          if (indexToRemove >= 0) {
            this.layersToRemove.splice(indexToRemove, 1);
          }

          if (layer.ws_group === ws.ws_group && layer.ws_name === ws.name) {
            ws.ws_id = ws.ws_id || '';
            ws.animation_limit_date_type = finder.animation_limit_date_type || '';
            ws.animation_limit_future = finder.animation_limit_future || '';
            ws.animation_limit_past = finder.animation_limit_past || '';
            ws.animation_step_date_type = finder.animation_step_date_type || '';
            ws.animation_step = finder.animation_step || '';
            ws.archive_start_date = finder.archive_start_date || '';
            ws.refresh_interval_step_type = finder.refresh_interval_step_type || '';
            ws.refresh_interval_step = finder.refresh_interval_step || '';

            const filtered = this.wsLayersSelected.find(item => item.name === layer.name && item.ws_name === layer.ws_name && item.ws_group === layer.ws_group);

            const translations = [];
            filtered.languages.forEach(lang => translations.push(filtered[lang]));

            const obj2 = {
              ws_id: layer.ws_id || '',
              layer_name: layer.name,
              layer_type: await getLayerInfo(ws.name, layer.name, 'type'),
              layer_color: filtered.layer_color,
              layer_label: filtered.layer_label,
              udm: filtered.udm,
              languages: filtered.languages,
              translations: translations
            };
            ws.layers.push(obj2);
          }
        }
      }

      this.WSForm.ws_name.forEach(item => {
        if (item.layers.length == 0) {
          this.WSForm.ws_name.splice(this.WSForm.ws_name.indexOf(item), 1);
        }
      });

      const formForCall = { ...this.WSForm };
      delete formForCall.ws_layers;
      formForCall.to_delete = this.layersToRemove.map(item => `${item.ws_name}:${item.name}_serviceID:${this.services.service_id}.label`).concat(this.layersToRemove.map(item => `${item.ws_name}:${item.name}_serviceID:${this.services.service_id}.udm`));
      this.layersToRemove = [];

      if (this.$route.params.state == 'DRAFT') {
        await this.createWorkspaceServiceAction(formForCall);
      } else {
        await this.updateWorkspaceServiceAction(formForCall);
      }

      await this.storeServices();
      this.$router.push('/services');
      this.WSForm = {};
      this.wsService.selectedWsConfiguration = [];
      this.overlay = false;
    },
    addToListWorkspaceToCombine(item) {
      this.WSForm.workspaceToCombine.push(item);
    },
    addToListLayerToCombine(item) {
      this.WSForm.layerToCombine.push(item);
    },
    async goToStep2() {
      this.overlay = true;
      await this.storeWSLayers(this.wsService.selectedWsConfiguration, true);
      this.e6 = 2;
      this.overlay = false;
    },
    selectGroup(item) {
      this.WSForm.ws_layers.forEach(e => {
        if (e.ws_group == undefined) {
          e.ws_group = this.groupToAdd;
        }

        if (e.ws_name == undefined) {
          e.ws_name = item.workspace_name;
        }

        if (e.layer_color == undefined) {
          e.layer_color = '#' + (Math.random().toString(16) + '000000').substring(2, 8);
        }

        if (e.layer_label == undefined) {
          e.layer_label = '';
        }

        if (e.udm == undefined) {
          e.udm = '';
        }

        if (e.languages == undefined) {
          e.languages = [];
        }
      });
      this.WSForm.ws_layers.forEach(layer => {
        this.WSForm.ws_name.findIndex(item => {
          return item.ws_group === layer.ws_group && item.name === layer.ws_name;
        }) === -1 ?
          this.WSForm.ws_name.push({ ws_group: layer.ws_group, name: layer.ws_name }) : log.error('item exist');
      });
      this.wsLayersSelected = JSON.parse(JSON.stringify(this.WSForm.ws_layers));
    },
    openTranslationTab(item) {
      this.selectedLayer = item;
      this.isTranslating = false;
      this.$nextTick(() => {
        this.isTranslating = true;
      });
    },
    closeTranslationTab() {
      this.selectedLayer = {};
      this.isTranslating = false;
    },
    updateInfo() {
      this.WSForm.info = this.WSForm.info.filter(e => e.lang !== 'it');
      this.WSForm.info.push({ lang: 'it', text: this.info.trim() });
    },
    updateTranslatedInfo() {
      try {
        const LANG = this.selectedLanguage.split('-')[0];
        this.WSForm.info = this.WSForm.info.filter(e => e.lang !== LANG);
        this.WSForm.info.push({ lang: LANG, text: this.info_translation.trim() });
        this.$store.dispatch(
          'storeToastMessage',
          {
            text: 'Translation added!',
            type: 'success'
          },
          { root: true }
        );
      } catch (err) {
        this.$store.dispatch(
          'storeToastMessage',
          {
            text: 'Something went wrong while trying to add translation.',
            errObj: err,
            type: 'danger'
          },
          { root: true }
        );
      }
    },
    changeSelectedLanguage() {
      this.info_translation = '';
      const LANG = this.selectedLanguage.split('-')[0];
      this.info_translation = this.WSForm.info.find(e => e.lang === LANG)?.text || '';
    },
    remove(item) {
      const index = this.wsLayersSelected.indexOf(item);
      this.wsLayersSelected.splice(index, 1);
      this.layersToRemove.push(item);
      const indexWS = this.WSForm.ws_layers.findIndex(layer => layer.name === item.name);
      this.WSForm.ws_layers.splice(indexWS, 1);
    }
  }
};
</script>

<style>
#layerColor {
  height: 20px;
  width: 20px;
  border: 2px solid black;
}
</style>
