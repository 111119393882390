import {
  createService,
  editService,
  getServices,
  deleteService,
  createUrlService,
  createDocumentalService,
  editUrlService,
  createUrlServiceTranslation,
  editDocumentalService,
  getUrlServiceConfiguration,
  getDocumentalServiceConfiguration,
  deleteUrlService,
  deleteDocumentalService,
  createTranslationService,
  createDocumentalServiceTranslation,
  getTranslationServices,
  editTranslationService,
  linkServices,
  getTranslationNodes,
  editTranslationNodes,
  listLinkedServices,
  listLinkedCustomers,
  unlinkServices,
  getTranslation,
  createReportHubService,
  createReportHubServiceTranslation,
  editReportHubService,
  getReportHubServiceConfiguration,
  deleteReportHubService
} from '@/api/api.js';

export const state = {
  headers: [
    {
      text: 'Application Name',
      value: 'service_name',
      width: '25%'
    },
    {
      text: 'Description',
      value: 'description',
      width: '20%'
    },
    {
      text: 'Type',
      value: 'service_type',
      width: '5%'
    },
    {
      text: 'Application Policy',
      value: 'application_policy',
      width: '50%'
    }
  ],
  linkHeaders: [
    {
      text: 'Application Name',
      value: 'service_name'
    },
    {
      text: 'Application Description',
      value: 'description'
    },
    {
      text: 'Application Type',
      value: 'service_type'
    },
    {
      text: 'Application State',
      value: 'service_state'
    }
  ],
  appUserLinkHeaders: [],
  services: [],
  activeServices: [],
  linkedServices: [],
  unlinkedServices: [],
  linkedCustomers: [],
  existingServicesConfiguration: [],
  hierarchyItems: [],
  hierarchyItemsLinear: [],
  storeTranslation: [],
  storeTranslationNodes: {},
  storeServiceForm: {},
  storeTranslationServiceForm: {
    service_name: '',
    description: ''
  },
  service_id: '',
  service_name: '',
  showCreateService: false,
  showCreateFather: false,
  showCreateChildren: false,
  isTranslating: false,
  isEditingTranslation: false,
  isEditingTranslationNodes: false,
  storeFatherSort_id: 0,
  storeChildrenSort_id: 0
};

export const mutations = {
  PUSH_SERVICE(state, service) {
    state.services.push(service);
  },
  SET_SERVICES_LINK_HEADERS(state, linkHeaders) {
    state.appUserLinkHeaders = linkHeaders;
  },
  SET_ACTIVE_SERVICES(state, linkHeaders) {
    state.activeServices = linkHeaders;
  },
  SET_LINKED_SERVICES(state, data) {
    state.linkedServices = data;
  },
  SET_LINKED_CUSTOMERS(state, data) {
    state.linkedCustomers = data;
  },
  SET_UNLINKED_SERVICES(state, data) {
    state.unlinkedServices = data;
  },
  GET_SERVICES(state, servicesArray) {
    state.services = [];
    state.services = servicesArray;
  },
  GET_TRANSLATION_SERVICES(state, translationObject) {
    state.storeTranslationServiceForm = {};
    state.storeTranslationServiceForm = translationObject;
    state.isEditingTranslation = false;

    if (state.storeTranslationServiceForm.service_name != '') {
      state.isEditingTranslation = true;
    }
  },
  GET_TRANSLATION_LABEL(state, translationObject) {
    state.storeTranslation = [];

    if (translationObject.length > 0) {
      state.storeTranslation = translationObject[0].label_value;
    }
  },
  GET_TRANSLATION_NODES(state, translationObjectArray) {
    state.isEditingTranslationNodes = false;
    translationObjectArray.forEach(item => {
      if (item.node_name != '' && item.node_name != null) {
        state.isEditingTranslationNodes = true;
      }
    });
    state.storeTranslationNodes = translationObjectArray;
  },
  GET_URL_SERVICES_CONFIGURATION(state, UrlServicesConfiguration) {
    state.hierarchyItemsLinear = UrlServicesConfiguration;
  },
  GET_DOCUMENTAL_SERVICES_CONFIGURATION(
    state,
    DocumentalServicesConfiguration
  ) {
    state.hierarchyItemsLinear = DocumentalServicesConfiguration;
  },
  GET_REPORT_HUB_SERVICES_CONFIGURATION(
    state,
    ReportHubServicesConfiguration
  ) {
    state.hierarchyItemsLinear = ReportHubServicesConfiguration;
  }
};

// ---------------------- GETTERS ---------------------
export const getters = {
  getServiceById: state => id => {
    return state.services
      ? state.services.filter(service => service.service_id === id)
      : [];
  }
};

// ---------------------- SERVICES ---------------------
export const actions = {
  async linkServicesAction({ dispatch }, data) {
    try {
      await linkServices(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Services linked',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'linkServicesAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async unlinkServicesAction({ dispatch }, data) {
    try {
      await unlinkServices(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Services unlinked',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'unlinkServicesAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async createServiceAction({ dispatch, commit }, data) {
    try {
      const service = await createService(
        data.description,
        data.service_name,
        data.service_type
      );
      commit('PUSH_SERVICE', service.data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Application created',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'createServiceAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async editServiceAction({ dispatch }, data) {
    try {
      await editService(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Application edited',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'editServiceAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async editTranslationServiceAction({ dispatch }, data) {
    try {
      await editTranslationService(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Application translation edited',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'editTranslationServiceAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async storeServices({ dispatch, commit }) {
    try {
      const dbServices = await getServices();
      commit('GET_SERVICES', dbServices.data.listServices);
      const activeServices = [
        ...dbServices.data.listServices.filter(e => {
          if (e.service_state == 'ACTIVE') {
            return e;
          }
        })
      ];
      commit('SET_ACTIVE_SERVICES', activeServices);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeServices',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async storeLinkedServices({ state, commit, dispatch }, data) {
    try {
      const respLinkedServices = await listLinkedServices(data);
      const linkedServiced = respLinkedServices.data.listLinkedServices;
      linkedServiced.forEach(service => {
        service.application_policy = [];
        service.selected_policy = {};
        service.isLoading = false;
      });
      commit('SET_LINKED_SERVICES', [
        ...linkedServiced
      ]);
      const unlinkedServices = [
        ...state.activeServices.filter(
          el =>
            linkedServiced.filter(
              check => check.service_id == el.service_id
            ).length <= 0
        )
      ];
      commit('SET_UNLINKED_SERVICES', unlinkedServices);

      const headerActiveServices = [
        { text: 'Username', value: 'username' }
        //{ text: "Name", value: "_name" },
        //{ text: "Surname", value: "surname" },
        //{ text: "Email", value: "email" }
      ];
      linkedServiced.forEach(e => {
        headerActiveServices.push({
          id: e.service_id,
          text: e.service_name,
          value: e.service_id,
          align: 'center',
          sortable: false
        });
      });
      commit('SET_SERVICES_LINK_HEADERS', headerActiveServices);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeLinkedServices',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async storeLinkedCustomers({ commit, dispatch }, data) {
    try {
      const respLinkedCustomers = await listLinkedCustomers(data);
      commit('SET_LINKED_CUSTOMERS', respLinkedCustomers.data.listLinkedCustomers);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeLinkedCustomers',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async deleteServiceAction({ dispatch }, data) {
    try {
      await deleteService(
        data.service_id,
        data.service_name,
        data.service_type,
        data.ws_id
      );
      dispatch(
        'storeToastMessage',
        {
          text: 'Application deleted',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'deleteServiceAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  // ---------------------- URL SERVICE ---------------------
  async storeGetUrlServiceConfiguration({ dispatch, commit }, service_id) {
    try {
      const dbUrlServiceConfiguration = await getUrlServiceConfiguration(
        service_id
      );
      commit(
        'GET_URL_SERVICES_CONFIGURATION',
        dbUrlServiceConfiguration.data.getUrlServiceConfiguration
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeGetUrlServiceConfiguration',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async createUrlServiceAction({ dispatch }, data) {
    try {
      await createUrlService(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Url configuration created',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'createUrlServiceAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async editUrlServiceAction({ dispatch }, data) {
    try {
      await editUrlService(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Url configuration edited',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'editUrlServiceAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async deleteUrlServiceAction({ dispatch }, data) {
    try {
      await deleteUrlService(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Url configuration deleted',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'deleteUrlServiceAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  // ---------------------- DOCUMENTAL SERVICE ---------------------
  async storeGetDocumentalServiceConfiguration(
    { dispatch, commit },
    service_id
  ) {
    try {
      const dbDocumentalServiceConfiguration = await getDocumentalServiceConfiguration(
        service_id
      );
      commit(
        'GET_DOCUMENTAL_SERVICES_CONFIGURATION',
        dbDocumentalServiceConfiguration.data.getDocumentalServiceConfiguration
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeGetDocumentalServiceConfiguration',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async createDocumentalServiceAction({ dispatch }, data) {
    try {
      await createDocumentalService(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Documental configuration created',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'createDocumentalServiceAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async editDocumentalServiceAction({ dispatch }, data) {
    try {
      await editDocumentalService(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Documental configuration edited',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'editDocumentalServiceAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async deleteDocumentalServiceAction({ dispatch }, data) {
    try {
      await deleteDocumentalService(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Documental configuration deleted',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'deleteDocumentalServiceAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  // ---------------------- REPORT HUB SERVICE ---------------------
  async storeGetReportHubServiceConfiguration({ dispatch, commit }, service_id) {
    try {
      const dbReportHubServiceConfiguration = await getReportHubServiceConfiguration(service_id);
      commit(
        'GET_REPORT_HUB_SERVICES_CONFIGURATION',
        dbReportHubServiceConfiguration.data.getReportHubServiceConfiguration
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeGetReportHubServiceConfiguration',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async createReportHubServiceAction({ dispatch }, data) {
    try {
      await createReportHubService(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'ReportHub configuration created',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'createReportHubServiceAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async editReportHubServiceAction({ dispatch }, data) {
    try {
      await editReportHubService(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'ReportHub configuration edited',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'editReportHubServiceAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async deleteReportHubServiceAction({ dispatch }, data) {
    try {
      await deleteReportHubService(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'ReportHub configuration deleted',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'deleteReportHubServiceAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  // ---------------------- TRANSLATIONS ---------------------
  async storeTranslationLayerLabel({ dispatch, commit }, data) {
    try {
      const resp = await getTranslation(data);
      commit('GET_TRANSLATION_LABEL', resp.data.getTranslation);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeTranslationLayerLabel',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async storeTranslationServices({ dispatch, commit }, data) {
    try {
      const resp = await getTranslationServices(data);
      commit('GET_TRANSLATION_SERVICES', resp.data.listTranslationServices);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeTranslationServices',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async createTranslationServiceAction({ dispatch }, data) {
    try {
      await createTranslationService(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Application translated',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'createTranslationServiceAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async createUrlServiceTranslationAction({ dispatch }, data) {
    try {
      await createUrlServiceTranslation(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Url configuration translated',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'createUrlServiceTranslationAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async createDocumentalServiceTranslationAction({ dispatch }, data) {
    try {
      await createDocumentalServiceTranslation(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Documental configuration translated',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'createDocumentalServiceTranslationAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async createReportHubServiceTranslationAction({ dispatch }, data) {
    try {
      await createReportHubServiceTranslation(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'ReportHub configuration translated',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'createReportHubServiceTranslationAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async storeGetTranslationNodes({ dispatch, commit }, data) {
    try {
      const resp = await getTranslationNodes(data);
      commit('GET_TRANSLATION_NODES', resp.data.listTranslationNodes);
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'storeGetTranslationNodes',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  },
  async editTranslationNodesAction({ dispatch }, data) {
    try {
      await editTranslationNodes(data);
      dispatch(
        'storeToastMessage',
        {
          text: 'Service configuration translation edited',
          type: 'success'
        },
        { root: true }
      );
    } catch (err) {
      dispatch(
        'storeToastMessage',
        {
          text: 'editTranslationNodesAction',
          errObj: err,
          type: 'danger'
        },
        { root: true }
      );
    }
  }
};
